<template>
  <el-select v-model="exporter" placeholder="Выберите экспортера">
    <el-option
      v-for="item in options"
      :key="item.id"
      :label="item.name"
      :value="String(item.id)"
    />
  </el-select>
</template>
<script>
export default {
  name: 'ExporterSelect',
  props: {
    id: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    exporter: {
      get() {
        return this.id
      },
      set(value) {
        this.$emit('change', value)
      },
    },
  },
}
</script>

<style lang="sass" scoped>
.el-select
  width: 500px
  max-width: 100%
</style>
