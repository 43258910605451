var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-select",
    {
      attrs: { placeholder: "Выберите экспортера" },
      model: {
        value: _vm.exporter,
        callback: function ($$v) {
          _vm.exporter = $$v
        },
        expression: "exporter",
      },
    },
    _vm._l(_vm.options, function (item) {
      return _c("el-option", {
        key: item.id,
        attrs: { label: item.name, value: String(item.id) },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }